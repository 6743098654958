@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Myriad.ttf') format('truetype');
}

@font-face {
  font-family: 'Abril';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Abril.ttf') format('truetype');
}

body {
  $background-color: white;
  $track-color: black;

  margin: 0;
  font-family: Myriad Pro Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	line-height: 1;

  &::-webkit-scrollbar {
    width: 0.9vw;
  }
  & {
    scrollbar-width: thin;
    scrollbar-color: $track-color $background-color;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $track-color;
    border-radius: 1vw;
    border: 0.2vw solid $background-color;
  }
}

html, body, button, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
  font: inherit;
	vertical-align: baseline;
  letter-spacing: 0.03em;
}
a{
  color: black;
  cursor: pointer;
}
button{
  background: transparent;
}

ol, ul {
	list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg{
  overflow: visible;
  display: block;
}

p{
  padding: 0;
  margin: 0;
}
#root{
  overflow: hidden;
}