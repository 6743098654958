.projects-root{
    position: relative;
    height: 100vh;
    box-sizing: border-box;
    width: 50%;

    .scroll{
        position: relative;
        height: 100%;
        display: inline-block;
        width: 100%;
    }

    @media only screen and (min-aspect-ratio: 10 / 6 ) {
        overflow: hidden;
        
        &.second-slide{
            .scroll{
                transform: translateX(calc(-100vw - 64vh));
            }
        }

        &.third-slide{
            .scroll{
                transform: translateX(calc(-200vw + 20vh));
            }
        }

        .scroll{
            width: auto;
        }
    }

    @media only screen and (max-aspect-ratio: 10 / 6) and (min-aspect-ratio: 6 / 11){
        .scroll{
            width: 100%;
        }
    }
}

.projects-section{
    .scroll{
        transition: transform 1.5s;
    }
    
    .projects-root{
        @media only screen and (max-aspect-ratio: 10 / 6 ) {
            height: 100%;
            overflow: visible;
        }
    }
}