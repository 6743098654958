.cources{
    > :first-child .name{
        fill: white;
        margin-right: auto;
    }
    .work{
        color: white;
    }
    .name{
        height: 35%;
        margin-right: auto;
    }

    .list-svg{
        fill: white;
    }

    .year{
        height: 30%;
        fill: black;
    }

    .angular{        
        path{
            fill: white;
        }
        
        g{
            filter: url(#year-icon-shadow)
        }
    }

    .react{
        > circle{
            fill: none;
        }
        g{
            filter: url(#year-icon-shadow)
        }
    }

    .spring{
        path{
            filter: url(#shape-shadow)
        }
    }

    :not(:first-child) .content{
        padding-right: 5%;

        .rect{
            height: 4%;
            fill: black;
            padding: 0.1vw;
            rect{
                filter: none;
            }
        }
        
        @media only screen and (min-width: 700px) and (orientation: landscape){
            .rect{
                height: 8%;
            }
        }
        span:first-child{
            margin-right: auto;
        }

        > :last-child {
            fill: black;
            transform: translate(44%, -52%);
            height: 72.5%;
            position: absolute;
            right: 0;

            &.spring{
                transform: translate(44%, -73%);
                height: 55%;
            }
        }
    }
}