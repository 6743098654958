.dots{
    display: flex;
    align-items: flex-start;

    svg{
        width: 10%;
        fill: black;
        position: relative;
        margin-right: 1.4vw;
    
        circle{
            filter: url(#dot-shadow)
        }
    }

    @media only screen and (min-width: 700px) and (orientation: landscape){
        svg{
            margin-right: 0.45vw;
        }
    }
}

.overflow {
    > div:nth-of-type(1) .dots > svg:nth-child(-n+4),
    > div:nth-of-type(2) .dots > svg:nth-child(-n+3),
    > div:nth-of-type(3) .dots > svg:nth-child(-n+2){
        width: 10%;
        fill: white;
    }
}
