@use '@sassUtils/classGenerators/colors';

.tasks-animation{
    .shadow{
        filter: url(#tasks-shadow);
    }
    
    &:hover{
        .top{
            animation: transform 6s infinite;

            :nth-child(2){
                animation: show 12s infinite;
            }
            rect:last-of-type{
                animation: hide 12s infinite;
            }
        }
        .bottom{
            :nth-child(2){
                animation: hide 12s infinite;
            }
            rect:last-of-type{
                animation: show 12s infinite;
            }
        }
    }
    .calendar{
        transform-box: fill-box;
        transform-origin: 80% top;
    }

    .top{
        .fill-primary{
            filter: none;
        }

        :nth-child(2){
            opacity: 0;
        }

        rect:last-child{
            opacity: 1;
        }
    }

    .bottom{
        :nth-child(2){
            opacity: 1;
        }

        rect:last-child{
            opacity: 0;
        }
    }

    @keyframes transform {
        0%{
            transform: rotate(0) scale(1) translateX(0) scale(1);
        }
        50%{
            transform: rotate(45deg) scale(0.85) translateX(-50px) scale(0.9);
            opacity: 0;
        }
        50.1%{
            transform: rotate(0) scale(1) translateX(0) scale(1);
            opacity: 1;
        }
        100%{
            transform: rotate(45deg) scale(0.85) translateX(-50px) scale(0.9);
            opacity: 0;
        }
    }

    @keyframes hide {
        0%, 25%{
            opacity: 1;
        }
        25.05%, 50%{
            opacity: 0;
        }
        50.1%, 75%{
            opacity: 1;
        }
        75.05%, 100%{
            opacity: 0;
        }
    }

    @keyframes show {
        0%, 25%{
            opacity: 0;
        }
        25.05%, 50%{
            opacity: 1;
        }
        50.1%, 75%{
            opacity: 0;
        }
        75.05%, 100%{
            opacity: 1;
        }
    }
}