.app{
    width: 100vw;
    
    .transition-container{
        display: flex;
        height: 100%;  
        width: 200%;
        transform: translateX(0);
        transition: transform 1.5s;
    }

    &.projects-section .transition-container{
        transform: translateX(-50%);
        height: 100%;

        @media only screen and (min-aspect-ratio: 10 / 6 ) {
            height: 100vh;
        }

        @media only screen and (max-aspect-ratio: 10 / 6){
            height: 100%;
        }
    }
}