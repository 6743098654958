.projects-root .background{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;

    svg{
        position: absolute;
    }

    .restaurant-blob{
        fill: var(--clr-restaurant-primary);

        path{
            filter: url(#restaurant-blob-shadow);
        }
    }
    
    .profile-blob {
        fill: var(--clr-profile-primary);

        path{
            filter: url(#profile-blob-shadow);
        }
    }

    .tasks-blob{
        fill: var(--clr-tasks-primary);

        path{
            filter: url(#tasks-blob-shadow);
        }
    }

    .chat-blob{
        fill: var(--clr-chat-primary);

        path{
            filter: url(#chat-blob-shadow);
        }
    }
    
    .extensions-blob{
        fill: var(--clr-extensions-primary);

        path{
            filter: url(#extensions-blob-shadow);
        }
    }

    @media only screen and (min-aspect-ratio: 10 / 6 ) {
        width: 100%;

        .tasks-blob{
            height: 82%;
            left: 28vh;
            top: -45%;
        }

        .restaurant-blob {
            height: 75%;
            left: 98vw;
            transform: rotate(199deg);
            top: -57%;
        }

        .extensions-blob {
            top: 68%;
            left: 200vw;
            height: 114%;
            transform: translateX(-30%) rotate(2deg);
        }

        .profile-blob {
            height: 139%;
            top: 55%;
            left: 100vw;
            transform: translate(-60%);
        }

        .chat-blob {
            width: 153vh;
            top: -117vh;
            left: calc(300vw - 129vh);
            transform: rotate(175deg);
        }
    }

    @media only screen and (max-aspect-ratio: 10 / 6) and (min-aspect-ratio: 6 / 11){
        width: 100vw;
       
        .tasks-blob {
            width: 87%;
            left: -32%;
            transform: translateY(-63%);
            height: auto;
            top: 0;
        }

        .profile-blob {
            top: 72vh;
            transform: translateY(-27vw);
            width: 108%;
            left: 70%;
            height: auto;
        }

        .restaurant-blob {
            top: 120vh;
            transform: rotate(93deg);
            left: -48%;
            width: 86%;
            height: auto;     
        }

        .extensions-blob {
            top: 138vh;
            transform: translateY(20vw) rotate(296deg);
            width: 100%;
            right: -73%;
            height: auto;
            left: auto;
        }

        .chat-blob{
            top: 243vh;
            transform: rotate(281deg);
            width: 100%;
            left: -54%;
        }
    }

    @media only screen and (max-aspect-ratio: 6 / 11 ) {
        .tasks-blob{
            width: 104%;
            left: -32%;
            transform: translateY(-54%);
        }

        .profile-blob{
            top: 72vh;
            transform: translateY(-95vw);
            width: 150%;
            left: 70%;
        }

        .restaurant-blob{
            top: 96vh;
            transform: rotate(93deg) translateX(3vw);
            left: -48%;
            width: 86%;
        }

        .extensions-blob{
            top: 136vh;
            transform: rotate(-68deg) translateX(18vw);
            right: -72%;
            width: 100%;
        }

        .chat-blob{
            top: 181vh;
            transform: translateY(-32vw) rotate(112deg);
            left: -75%;
            width: 100%;
        }
    }

    @media only screen and (max-aspect-ratio: 10 / 13) and (min-aspect-ratio: 6 / 11){
        .chat-blob{
            top: 209vh;
            transform: translateY(22vw) rotate(281deg);
        }
    }

    @media only screen and (max-aspect-ratio: 12 / 10) and (min-aspect-ratio: 6 / 11){
        .restaurant-blob{
            top: 96vh;
            transform: translateY(30vw) rotate(93deg);
        }
    }
}
