@use '@sassUtils/classGenerators/strokes' as *;
@use '@sassUtils/classGenerators/colors' as *;

.restaurant-animation{
    transform: translateY(0);

    .shadow {
        filter: url(#restaurant-blob-shadow);
    }

    .tick{
        stroke: var(--clr-restaurant-primary);
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 3.5px;
        fill: none;
    }

    line{
        stroke-width: var(--stroke-200);
        stroke: var(--clr-restaurant-primary);
    }

    .bar,
    .left-bar{
        transition: transform 1.5s;
        filter: url(#restaurant-blob-shadow);
    }

    .phones{
        transform-origin: center;
        transform-origin: 89% 46%;  
        transition: transform 1.5s, opacity 1.5s;
    }

    .indicator{
        stroke: black; 
        stroke-width: 4;
        fill: none;
        opacity: 0;
        stroke-dasharray: 72;
    }

    .menu-bar{
        transition: width 1.5s, opacity 0s 1.5s;
        width: 23px;
        filter: url(#restaurant-blob-shadow);
    }

    .btn{
        transform: scale(0);
    }
    
    &.visited{
        .bar{
            transform: rotate(-90deg) translate(-389px, 145px) scale(0.8);
        }

        .left-bar{
            transform: rotate(90deg) translate(86px, -287px) scale(0.8);
        }

        .phones{
            transform: scale(0.7) translate(18px, -41px) rotate(-26deg);
            transform-box: fill-box;
        }

        .phones > g{
            transform-origin: center;
            transform-box: fill-box;
        }

        .primary-phone{
            animation: shake 0.3s alternate-reverse infinite;
        }

        .secondary-phone{
            animation: shake 0.3s alternate infinite;
        }

        .times{
            opacity: 0;
        }

        .indicator{
            opacity: 1;
            animation: complete 2.5s infinite;
        }

        .menu-bar{
            opacity: 1;
            width: 131px;
            transition: width 1.5s, opacity 0s;
        }

        .btn{
            transform: scale(1);
        }

        .wait-100{
            transition: transform 1s 0.8s;
        }

        .wait-200{
            transition: transform 1s 1s;
        }

        .wait-300{
            transition: transform 1s 1.2s;
        }
    }

    @keyframes complete{
        0%{
            stroke-dashoffset: 72;
        }
        
        40%{
            stroke-dashoffset: 20;
        }
        41%{
            stroke-dashoffset: 2;
        }
        100%{
            stroke-dashoffset: -43
        }
    }

    @keyframes shake{
        0%{
            transform: rotate(3deg);
        }
        100%{
            transform: rotate(-3deg);
        }
    }
}