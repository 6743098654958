@use '@sassUtils/maps/colors' as *;

@each $project, $types in $projects {
    @each $type, $value in $types {
        .#{$project}-animation{
            .fill-#{$type}{
                fill: #{$value};
            }
            .stroke-#{$type}{
                stroke: #{$value};
            }
        }
    }
}