@use './maps/colors';
@use './maps/strokes';

:root {
    @each $project, $colors in colors.$projects {
        @each $color, $value in $colors {
            --clr-#{$project}-#{$color}: #{$value};
        }
    }

    @each $stroke, $value in strokes.$svg {
        --stroke-#{$stroke}: #{$value};
    }
}