@use 'sass:math';

$ratio: var(--ratio);

.cv{
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    position: relative;
    z-index: 3;
    background: hsl(0 9% 98% / 1);
    width: 50%;
    box-sizing: border-box;

    svg{
        *{
            transition: fill 4s 0.5s, stroke 4s 0.5s;
        }
    }

    .github path{
        stroke-width: 1px;
        stroke: black;
        filter: url(#github-shadow); 
    }
      
    .translate-wrapper{
        transform-origin: top;
        display: flex;
        padding-bottom: 6%;
        width: 100%;
        margin: auto;
        position: relative;
        flex-direction: column;
        transform: scale(1);

        &.animate{
            transform: scale(calc(100% * $ratio));
        
            .mid-logo circle,
            .technologies .names svg,
            .projects > :last-child .circle circle,
            .background .circle circle,
            .jenkins .circle{
                fill:black;
                stroke: black;
            }
            .jenkins .towel{
                fill: white;
            }
        }
        &.animated{
            transition: transform 7s;
        }
    }

    .panels{
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    @media only screen and (min-width: 700px) and (orientation: landscape) {
        .translate-wrapper{
            padding-bottom: 4%;    
            width: 60%;
        }

        .panels{
            flex-direction: row;
        }
    }

    @media only screen and (min-width : 896px) and (max-width: 1100px){
        .translate-wrapper{
            width: 86%;
        }
    }

    @media only screen and (min-width: 700px) and (max-width : 896px) and (orientation : landscape) { 
        .translate-wrapper{
            width: 95%;
        }
    }

    &.finished-initial{
        height: auto;

        svg{
            *{
                transition: fill 12s 12s, stroke 12s 12s;
            }
        }
    }
}

.projects-section .cv{
    height: 100vh;
}
