.cv .projects{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .clip .circle{
        position: absolute;
        display: none;
    }
    
    .circle{
        fill: var(--clr-primary)
    }
    
    :first-child{
        .clip .circle{
            right: -23%;
            top: -33%;
            width: 57%;
            filter: url(#shape-shadow);
            display: block;
        }
    }

    > :nth-child(2){
        width: 90%;
        
    
        .clip .circle{
            right: 4%;
            top: 8%;
            width: 5%;
        }
    }

    > :nth-child(3){
    
        .clip{
            overflow: visible;
    
            .circle{
                right: -0;
                top: 0;
                width: 18%;
                transform: translate(35%, -35%);
            }
        }
    }

    > :nth-child(4){
    
        .clip .circle{
            right: -0;
            top: 0;
            width: 18%;
            transform: translate(27%, -27%);
        }
    }
    
    > :nth-child(5){
        .clip .circle{
            right: -19%;
            top: -36%;
            width: 51%;
            display: block;
        }
    }

    @media only screen and (min-width: 700px) and (orientation: landscape) {
        align-items: flex-start;

        > :nth-child(2){
            width: 59.5%;    
        }
    }
}

.first-style .projects{
    > :nth-child(n+2):nth-child(-n+4){
        circle{
            fill: white;
        }
    }


    > :nth-child(5){
        .clip circle{
            fill: var(--clr-accent);
            filter: url(#shape-shadow);
        }
    }
}