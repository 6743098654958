.cv .background{
    $safe-left: env(safe-area-inset-left);
    $safe-right: env(safe-area-inset-right);

    width: 90vw;
    height: 100%;
    position: absolute;
    left: -15vw;
    z-index: -1;

    svg{
        position: absolute;
    }

    div {
        position: absolute;
    }
    
    .mid-logo{
        width: 60%;
        left: -15%;
        top: 21%;
        filter: url(#name-shadow);
        
        circle{
            fill: var(--clr-animate);
            filter: url(#background-circle-shadow);
        }
    }

    .react{
        > circle, g{
            filter: url(#react-shadow);
        }

        > circle{
            fill: white;
        }
    }

    .react-icons{
        width: 42%;
        padding-bottom: 176%;
        top: -2.5%;
        left: 78.5%;
        transform: rotate(-52deg);

        svg{
            width: 24.5%;
        }

        > :nth-child(1){
            left: 26%;
        }

        > :nth-child(2){
            left: 41%;
            top: 31%;
        }
        
        > :nth-child(3){
            left: 46%;
            top: 59%;
        }
    }

    .letters{
        svg{
            position: relative;
            width: 50%;
        }
    
        &.project{
            width: 20%;
            padding-bottom: 20%;
            top: 46.5%;
            left: 114.5%;
    
            div{
                width: 43%;
                height: 20%;
                display: flex;
            }
    
            div:nth-child(1){
                top: 6%;
                left: 16%;
            }
    
            div:nth-child(2){
                top: 47%;
            }
        }

        &.bottom{
            width: 8%;
            height: 19vw;
            top: 56%;
            left: 43%;
            display: flex;
            align-items: flex-start;

            svg:first-child{
                margin-right: 10%;
            }
        }
    }

    .react-big{
        top: 77%;
        left: 71%;
        width: 7%;

        .react{
            width: 100%;
        }
    }

    @media only screen and (min-width: 700px) and (orientation : landscape) {
        .mid-logo{
            width: 13%;
            left: 46%;
            top: 39%;
        }

        .letters{
            &.project{
                width: 8%;
                padding-bottom: 8%;
                top: 47%;
                left: 37.5%;
            }
        }

        .cv .background .react-icons {
            width: 11%;
            padding-bottom: 11%;
            top: 52.5%;
            left: 52.5%;
            transform: rotate(0);
        }

        .react-icons{
            width: 11%;
            padding-bottom: 11%;
            top: 52.5%;
            left: 51.5%;
            transform: none;
        }
    }

    @media only screen and (min-width : 896px) and (max-width : 1100px ) and (orientation : landscape) {
        width: calc(130vw - $safe-left - $safe-right);
        left: calc(-21vw + ($safe-left + $safe-right) / 6);
        
    }

    @media only screen and (min-width: 700px) and (max-width : 896px) and (orientation: landscape){
        width: calc(140vw - $safe-left - $safe-right);
        left: calc(-23vw + ($safe-left + $safe-right) / 6);
    }
}