.list{
    font-size: 1.5vw;
    width: 70%;
    width: 105%;
    margin-bottom: 0.4vw;

    > div:first-child{
        fill: var(--clr-primary);
        width: 90%;
        margin-top: 0;
        margin-left: 5%;
    }
    > div:first-child:hover{
        transform: scale(1.05);
    }
    > div:not(:first-child){
        margin-top: 1.2%;
        fill: white;
    }

    .overflow > div{
        margin-top: 3.2%;
        fill: white;
    }
    
    > :first-child .name{
        height: 26%;
    }

    span{
        text-shadow: 0 0 1px;
        font-weight: bold;
        font-size: 1rem;
        font-family: 'Myriad Pro Regular';
    }

    .overflow{
        overflow: hidden;
        padding: 1% 5%;
        transition: all 2s;
    }

    @media only screen and (min-width: 700px) and (orientation: landscape) {
        > :first-child .name{
            height: 46%;
        }
        width: 100%;
    }
}
.name{
    fill: black;

    path{
        filter: url(#name-shadow);
    }
}

.list-svg{
    height: 29%;
    fill: black;
    margin-left: auto;
    margin-right: 6%;

    @media only screen and (min-width:700px) and (orientation:landscape){
        height: 38%;
    }
}