@use '@sassUtils/mixins/cvColors' as *;

.cv.first-style{
    @include getStyleColors('first-style'); 
}

.cv.third-style{
    @include getStyleColors('second-style'); 
}

.cv.third-style{
    @include getStyleColors('third-style'); 
}


.list.technologies{
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    
    > :first-child{
        display: block;
        margin-top: 0;
    }

    .technology svg:not(.circle, .react, .angular) {
        fill: black;
    }

    .list-svg{
        fill: black;
    }

    .overflow{
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        margin-top: 0;
        padding-top: 3.2%;
        transition: all 2s;
    }

    .ribon {
        fill: white;
        
        .name{
            fill: black;
        }
    }

    .names {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    
        > div{
            position: relative;
            text-align: center;
            height: 5.2vw;
            width: 21%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.75vw;
        }

        .name {
            height: 84%;
        }
    }

    .sql{
        width: 64%;
    }
    
    .rest{
        width: 70%;
    }
    
    .jwt{
        width: 60%;
    }

    .aws{
        width: 76%;
    }
    
    .node-js,
    .next-js{
        fill: black;
    }
    
    .next-js{
        stroke-width: 14px;
        fill: black;
        stroke: black;
    }
    
    .jenkins{
        .circle,
        .towel{
            fill: black;
        }
    
        .tie{
            fill: white;
        }
    }

    @media only screen and (min-width: 700px) and (orientation: landscape) {
        .names{
            > div{
                height: 1.3vw;
            }

            .name{
                height: 100%;
            }
        }
    }
}

.first-style .technologies {
    .node-js,
    .next-js{
        fill: var(--clr-animate);
    }

    .next-js{
        stroke: var(--clr-animate);
    }

    .jenkins{
        .circle,
        .towel{
            fill: var(--clr-animate);
        }
    }
}