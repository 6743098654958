.background .circles{
    position: absolute;
    width: 100%;
    height: 100%;

    div,
    svg{
        position: absolute;
    }

    circle{
        filter: url(#black-shadow);
    }

    .top-project-circles{
        width: 10%;
        top: 18%;
        left: 35%;
        padding-bottom: 10%;
    
        > {
            :first-child{
                width: 21%;
                left: 27.5%;
                top: 13.2%;
            }
    
            :last-child{
                width: 25%;
                left: 59.5%;
                top: 36.2%;
            }
        }
    }

    .top-left-circles{
        width: 15%;
        left: 7%;
        padding-bottom: 15%;
        top: 2%;

        > {
            :nth-child(1){
                width: 18%;
                left: 35.5%;
                top: 13.2%;
            }
    
            :nth-child(2),
            :nth-child(3){
                circle{
                    fill: white;
                    filter: url(#white-shadow);
                }
            }
            :nth-child(2){
                width: 18%;
                left: 67.7%;
                top: 24.45%;
            }
    
            :nth-child(3){
                width: 10%;
                left: 56.6%;
                top: 66.9%;
            }
        }

    }

    .top-right-circles{
        width: 15%;
        padding-bottom: 15%;
        right: 12%;
        top: 2%;
        > {

            :nth-child(1){
                width: 17%;
                left: 65.7%;
                top: 30.45%;
            }

            :nth-child(2){
                width: 17%;
                left: 39.7%;
                top: 11.45%;
                
                circle{
                    fill: white;
                    filter: url(#white-shadow);
                }
            }

            :nth-child(3){
                width: 12%;
                left: 43.7%;
                top: 56.45%;
            }
        }
    }

    .top-spiral{
        width: 78%;
        height: 28%;
        left: 49%;
        top: 21%;
        transform: rotate(24deg);

        > {
            :nth-child(1){
                width: 11%;
                left: 31.7%;
                top: 1.45%;
                display: none;
            }
    
            :nth-child(2){
                width: 11%;
                left: 48.5%;
                top: 13.2%;
            }
    
            :nth-child(3){
                width: 13%;
                left: 62.6%;
                top: 25.9%;
            }
    
            :nth-child(4){
                width: 16%;
                left: 68.6%;
                top: 42.9%;
            }
    
            :nth-child(5){
                width: 11%;
                left: 60.6%;
                top: 60.9%;
            }
    
            :nth-child(6){
                width: 12%;
                left: 46.6%;
                top: 74.9%;
            }
        }

    }

    .bottom-spiral{
        width: 115%;
        top: 45%;
        left: 8.5%;
        padding-bottom: 160%;

        > {
            :nth-child(1){
                width: 10%;
                left: 38%;
                top: 4%;
            }

            :nth-child(2){
                width: 8%;
                left: 39%;
                top: 17%;
            }

            :nth-child(3){
                width: 7%;
                left: 46%;
                top: 27%;
            }

            :nth-child(4){
                width: 11%;
                left: 52%;
                top: 36%;
            }

            :nth-child(5){
                width: 9%;
                left: 52.5%;
                top: 48%;
            }

            :nth-child(6){
                width: 7.5%;
                left: 51%;
                top: 59%;
            }

            :nth-child(7){
                width: 6.5%;
                left: 51%;
                top: 69%;
            }

            :nth-child(8){
                width: 5.5%;
                left: 55%;
                top: 78%;
          
                fill: var(--clr-accent);
            }

            :nth-child(9){
                width: 5.5%;
                left: 65%;
                top: 85%;
          
                fill: var(--clr-accent);
            }

            :nth-child(10){
                width: 5.5%;
                left: 79%;
                top: 92%;

                fill: var(--clr-accent);
            }
        }
    }

    .bottom-project-circles{
        width: 36%;
        padding-bottom: 52%;
        left: 97%;
        top: 81%;

        > {
            :nth-child(1){
                width: 33%;
            }

            :nth-child(2){
                width: 36%;
                top: 42%;
                left: 35%;
            }
        }
    }

    .bottom-circles{
        width: 91%;
        padding-bottom: 70%;
        left: 18%;
        top: 95%;

        :nth-child(1){
            width: 13%;
            top: 6%;
            left: 9%;
        }

        :nth-child(2){
            width: 13%;
            left: 51%;
        }

        :nth-child(3){
            width: 46%;
            top: 26%;
            left: 39%;
        }
    }

    @media only screen and (min-width: 700px) and (orientation : landscape) {
        .top-spiral{
            width: 15%;
            height: 28%;
            left: 47%;
            top: 15%;
            transform: rotate(0)
        }

        .bottom-spiral{
            width: 20%;
            top: 62%;
            left: 50.5%;
            padding-bottom: 26%;
        }

        .bottom-circles {
            width: 18%;
            padding-bottom: 20%;
            left: 40%;
            top: 63%;
        }

        .bottom-project-circles {
            width: 8%;
            padding-bottom: 8%;
            left: 38%;
            top: 75%;
        }
    }
}