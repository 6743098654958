.left-panel .project{
    position: relative;
    margin: 4% 0;
    width: 75%;
    color: #312929;
    font-family: Myriad Pro Regular;
    margin: 2.5% 0;
    padding-bottom: 30%;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    transition: padding 2s, transform 0s;
    background: hsl(0, 13%, 97%);

    &:hover{
        transform: scale(1.02);
    }

    .content{
        a,
        .github-container,
        .info{
            opacity: 1;
            transition: opacity 1s;
        }
    }

    &.hidden{
        .content{
            a,
            .github-container,
            .info{
                opacity: 0;
                pointer-events: none;
            }
        }
    }
    .content{
        position: absolute;
        height: 100%;
        width: 100%;
        padding: 2% 0 5% 4%;
        box-sizing: border-box;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        font-weight: bold;

        svg{
            margin-right: 2%;
        }

        .name{
            margin: 0;
            font-size: 4.2vw;
        }

        a{
            font-size: 2.1vw;
            text-decoration: underline;
            color: #312929;

            &:hover,
            &:focus{
                text-decoration: none;
                outline: none;
                transform: scale(1.1);
            }
        }

        .github-container{
            display: flex;
            align-items: center;
            height: 25%;

            svg{
                height: 50%;
                margin-right: 2%;
            }
        }

        .info{
            display: flex;
            align-items: center;
            height: 31%;
            width: 80%;
            font-size: 1.9vw;

            .circle{
                height: 36%;
                filter: url(#shape-shadow);
           }
        }
    }

    .clip{
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        pointer-events: none;
        border-radius: calc(0.55vw * 0.69 * 1);
    }

    .rect{
        display: block;
        width: 100%;
        fill: white;
    
        rect{
            filter: url(#shape-shadow);
        }
    }

    @media only screen and (min-width : 700px) and (orientation : landscape) {
        width: 51%;
        margin: 2.5% 0;

        .content{
            .name{
                font-size: 1.1vw;
            }

            a{
                font-size: 0.9vw;
            }

            .info{
                font-size: 0.8vw;
            }
        }
    }

    @media only screen and (min-width : 896px) and (max-width : 1100px ) and (orientation : landscape) {
        .content{
            .name{
                font-size: 1.8vw;
            }

            a{
                font-size: 1.3vw;
            }

            .info{
                font-size: 1.1vw;
            }
        }
    }

    @media only screen and (min-width: 700px) and (max-width : 896px) and (orientation : landscape) { 
        .content{
            .name{
                font-size: 2vw;
            }

            a{
                font-size: 1.5vw;
            }

            .info{
                font-size: 1.3vw;
            }
        }
    }
}
