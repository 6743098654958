.right-panel{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    > .dots {
        display: none;
    }



    @media only screen and (min-width: 700px) and (orientation: landscape) {
        width: 27%;
        display: block;

        > .dots{
            width: 59%;
        }
    }
}