header {
    position: fixed;
    transition: 1.5s all;
    width: 100%;
    z-index: 3;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) calc(env(safe-area-inset-left) + 1%);
    box-sizing: border-box;
    padding-top: 1vh;

    button, a{
        height: 6vh;
        
        &:hover,
        &:focus{
            transform: scale(1.1);
        }
    }

    a{
        display: block;
        position: relative;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 1rem;
        font-family: "Myriad Pro Regular";
        
        span{
            color: white;
            border-radius: 1vw;
            align-items: center;
            font-size: 1.8vh;
            font-weight: bold;
            display: flex;
            justify-content: center;
            position: absolute;
            width: 100%;
            left: 0;
            z-index: -1;
            top: 0;
            height: 100%;
        }

        .rect{
            position: relative;
            height: 100%;
            z-index: -1;
       
            rect{
                filter: url(#project-shadow);
                transition: 1.5s all;
            }
        }

        .arrow{
            height: 42%;
            fill: white;
            transform: rotate(90deg);
            margin-left: 0.3vw;
            transition: transform 1.5s;
        }
    }

    button{
        position: relative;
        display: block;

        .circle{
            height: 100%;
        }    
        
        .arrow{
            position: absolute;
            fill: white;
            width: 31%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }
    
    ul{
        display: flex;
    }
    
    .left-menu{
        transition: transform 1.5s;
    }

    .slide-left,
    .slide-right{
        opacity: 0;
    }

    .slide-right{
        margin-left: auto;
        margin-right: 1%;
        fill: var(--clr-profile-primary);
    }
    
    .slide-left{
        margin-left: 1vh;
        fill: var(--clr-tasks-primary);
        transform:  rotate(-180deg);
        transition: opacity 0s;
    }

    .left-menu{
        transition: transform 1.5s;
        transform: translateX(calc(97vw - 70% - env(safe-area-inset-right) - env(safe-area-inset-left)));
    }

    .slide-right,
    .slide-left{
        opacity: 0;
        pointer-events: none;

        circle{
            filter: url(#profile-shadow);
        }
    }

    @media only screen and (max-width : 896px) and (orientation : landscape) { 
        button,
        a{
            height: 10vh;
        }
    }
}
.projects-section header {
    .left-menu{
        transform: translateX(0);
    }

    .slide-left{
        transition: opacity 0.5s;
    }

    .sections-toggle{
        rect{
            fill: var(--clr-tasks-primary);
        }

        .arrow{
            transform: rotate(-90deg);
        }
    }
}

@media only screen and (min-aspect-ratio: 10 / 6 ) {
    .projects-section header {
        .slide-right {
            opacity: 1;
            transform: translateX(0);
            pointer-events: all;
            transition: opacity 0.5s 1s;
        }

        .left-menu{
            transform: translateX(0);
        }

        .sections-toggle{        
            rect{
                fill: var(--clr-tasks-primary);
            }
        
            .arrow{
                transform: rotate(-90deg);
            }
        }

        &.second-slide {
            .slide-right {
                circle{
                    transition: fill 0.5s 0.7s;
                    fill: var(--clr-extensions-primary);
                }
            }
        
            .sections-toggle{
                rect{
                    fill: var(--clr-extensions-primary);
                }
            }
        
            .slide-left{
                opacity: 1;
                pointer-events: all;
        
                circle{
                    fill: var(--clr-extensions-primary);
                }
            }
        
            .left-menu{    
                transform: translateX(calc(98vw - 100% - 7vh - env(safe-area-inset-left) - env(safe-area-inset-right)));
            }
        }
        
        &.third-slide {
            .sections-toggle{
                rect{
                    fill: var(--clr-extensions-primary);
                }
            }
        
            .slide-right {
                pointer-events: none;
                opacity: 0;
                transition: opacity 0.5s;
            }
        
            .slide-left{
                opacity: 1;
                pointer-events: all;
                
                circle{
                    fill: var(--clr-extensions-primary);
                }
            }
        }
    }
}

@media only screen and (max-width : 896px) and (orientation : landscape) { 
    header{
        button,
        a{
            height: 7vh;
        }
    }

    .projects-section header {
        &.second-slide {
            .left-menu{    
                transform: translateX(calc(98vw - 100% - 12vh - env(safe-area-inset-left) - env(safe-area-inset-right)));
            }
        }
    }
}