.technology{
    position: relative;
    font-size: 1vw;
    position: relative;
    display: inline-block;
    text-align: center;
    width: 15%;
    
    svg.circle {
        width: 100%;
        display: block;
    }

    .circle circle {
        filter: url(#shape-shadow);
    }

    svg:last-child {
        position: absolute;
        width: 59%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    svg:nth-of-type(2){
        fill: black;
    }

    .angular{
        fill: black;
    }

    .angular path{
        fill: white;
    }

    .aws :nth-child(n + 3) {
        fill: white;
    }

    .typescript path{
        fill: white;
    }

    .technology svg:nth-of-type(2).react{
        fill: white;
    }

    .react g {
        fill: white;
    }

    @media only screen and (min-width: 700px) and (orientation:landscape){
        width: 21%;
        opacity: 1;
    }
}
