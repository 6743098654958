.more-github{
    width: 46%;
    box-sizing: border-box;
    position: relative;
    font-family: 'Myriad Pro Regular';
    margin: auto;
    font-size: 3.2vw;


    &:hover,
    &:focus{
        transform: scale(1.1);
    }
    
    .content{
        position: relative;

        a{
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: black;
            text-shadow: 0 0 1px;
            font-weight: bold;
            padding: 0 5%;
            box-sizing: border-box;

            .github{
                width: 13%;
                margin-left: 5%;
            }
        }

        rect{
            fill: hsl(0, 13%, 97%);
            filter: url(#shape-shadow)
        }
    }

    @media only screen and (min-width: 700px) and (orientation: landscape) {
        font-size: 1.1vw;
        padding: 0 9%;
        margin: 0;
    }
}