@use '@sassUtils/maps/colors' as *;
@use 'sass:map';

@mixin getStyleColors($style) {
    $map: map.get($cv, $style);
    $secondary: #{map.get($map, 'secondary')};

    --clr-primary: #{map.get($map, 'primary')};
    --clr-secondary: $secondary;
    --clr-accent: #{map.get($map, 'accent')};

    @if map.has-key($map, 'animate') {
        --clr-animate: #{map.get($map, 'animate')};
    } @else {
        --clr-animate: $secondary;
    }
}