.profile-info{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 1% 0;
    width: 100%;

    .image-container{
        justify-content: center;
        align-items: center;
        display: flex;
        width: 47%;
        
        img{
            width: 100%;
        }
    }

    .data{
        flex-direction: row;
        width: 70%;
        font-size: 2.9vw;
        justify-content: center;
        font-family: Abril;
        display: flex;
        font-weight: bold;
        color: black;
        box-sizing: border-box;
        flex-wrap: wrap;
        font-family: Myriad Pro Regular;

        .info{
            display: flex;
            margin: 2vh 0;
            font-weight: bold;
            color: #312929;

            .icon{
                color: #e7e5e5;
                margin-right: 1vw;
            }
            a{
                color: #e7e5e5;
            }
            > div {
                background: #1f1f1f;
                border-radius: 7px;
                color: #e7e5e5;
                border-style: inset;
                border: 1px solid #a39f9f;
                box-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
                font-size: 1vw;
                font-size: 2vw;
                padding: 2vw;
                display: flex;
            }
        }

        a:hover,
        a:focus{
            transform: scale(1.1);
        }


        > div:nth-child(2n + 3){
            justify-content: flex-end;
        }

        .name{
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;

            .info-icon{
                width: 12%;
            }
        } 

        .info-name{
            width: 93%;
        }

        .info-icon{
            rect{
                filter: url(#info-shadow);
                fill: transparent;
            }

            width: 20%;
            margin-right: 2%;
        }
        .phone .info-icon{
            width: 11%;
        }
        .location .info-icon {
            width: 11%;
            margin-left: 5%;
        }
    }

    @media only screen and (min-width: 700px) and (orientation: landscape) {
        flex-direction: row;
        justify-content: flex-start;
        padding: 5%;
        padding-left: 0;
        flex-direction: row;


        .image-container{
            width: 24%;
            justify-content: flex-end;
            align-items: center;

            img{
                margin-right: 4%;
            }
        }

        .data{
            font-size: 1.2vw;
            width: 40%;
            padding: 2% 0px;
            margin-right: 2%;

            .info {
                display: flex;
                margin: 0;
                margin-top: 1vh;

                > div{
                    font-size: 0.8vw;
                    padding: 0.7vw 1vw;
                }

                .icon{
                    margin-right: 0.4vw;
                }
            }

            .info-icon{
                width: 14%;
                margin-right: 4%;
            }

            > div:nth-child(2n + 3){
                flex-direction: row-reverse;
            }

            .name{
                justify-content: left;
                box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
                padding: 6% 5%;
                border-radius: 0.8rem;
                border-top: 5px solid #1f1f1f;

                .info-icon{
                    margin-right: 2%;
                    width: 8%;
                }
            }

            .info-name{
                width: 61%;
            }

            .phone .info-icon{
                width: 8%;
                margin-right: 7%;
                margin-left: 3%;
            }
            .location .info-icon{
                width: 9%;
                margin-left: 3%;
            }
        }
    }
    @media only screen and (min-width : 896px) and (max-width : 1100px) {
        .data{
            font-size: 1.8vw;

            .info {
                > div{
                    font-size: 1.3vw;
                    padding: 0.8vw 1.5vw;
                }
            }
        }
    } 

    @media only screen and (min-width : 700px) and (max-width : 896px) {
        .data{
            .info {
                > div{
                    font-size: 1.5vw;
                    padding: 0.8vw 1.5vw;
                }
            }
        }
    } 

    @media only screen and (max-width : 896px) and (orientation : landscape) { 
        .data{
            font-size: 1.35vw;
        }
    }
}