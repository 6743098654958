.ribon{
    position: relative;
    opacity: 0.9;

    > svg{
        display: block;
    }

    rect{
        filter: url(#shape-shadow);
    }

    .content{
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        padding-left: 3%;
        z-index: 5;
        box-sizing: border-box;
        align-items: center;
    }

    @media only screen and (min-width: 700px) {
        opacity: 1; 
    }
}